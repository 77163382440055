<template>
  <div class="planner bg-app vh-100">
    <v-container fluid class="px-0">
      <v-row v-if="!loading">
        <v-col class="px-2">
          <v-card flat class="transparent">
            <div class="px-4 mx-1 mb-6">
              <div class="d-flex align-center">
                <v-btn small icon class="mr-2" @click="$router.push('/planners')">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div>
                  <span class="overline">{{
                    plan?.type === "report" ? "Report" : plan?.type === "tasks" ? "Tasks" : "Planner"
                  }}</span>
                  <v-chip
                    :color="statusColor(currentStatusCode)"
                    x-small
                    text-color="white"
                    class="ml-2 text-uppercase"
                  >
                    {{ currentStatusText }}
                  </v-chip>
                  <h2 class="mb-2" style="margin-top: -10px">{{ planner.name ? planner.name : "Planner" }}</h2>
                </div>
              </div>
              <div class="subtitle-1">{{ planner.description }}</div>
              <div class="ma-0 pa-0" v-if="dueDate?.dueDateTime">
                <div class="d-flex align-center">
                  <span class="text-subtitle-2 mr-2">Due:</span>
                  <span> {{ formatDateTime(dueDate.dueDateTime) }} </span>
                </div>
              </div>
              <v-divider class="my-4"></v-divider>
              <div v-for="(task, taskIndex) in tasks" :key="'stepItem-' + taskIndex" class="mb-8">
                <div>
                  <div>
                    <div class="overline" style="line-height: 12px">
                      {{ plan?.type === "report" ? `Inspection Item ${taskIndex + 1}` : `Task ${taskIndex + 1}` }}
                    </div>
                    <h3 :class="{ 'text-decoration-line-through': task.completed }" class="pb-2">{{ task.name }}</h3>
                  </div>
                </div>
                <div class="pb-2">{{ task.description }}</div>
                <div><span class="overline">Expected duration:</span> {{ task.duration }}</div>
                <div></div>
                <div v-if="task.prerequisites?.length">
                  <label class="overline">prerequisites</label>
                  <div class="mb-3">
                    <ul>
                      <li v-for="(p, idx) in task.prerequisites" :key="'p-' + idx">
                        {{ p }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-if="task.resources && task.resources.length && task.resources[0].name">
                  <label class="overline">Recommended resources</label>
                  <ul>
                    <li v-for="(r, idx) in task.resources" :key="'r-' + idx">{{ r.name }}</li>
                  </ul>
                </div>
                <div class="pt-3">
                  <div v-for="(hazard, hazardIndex) in task.hazards" :key="'hazardItem-' + hazardIndex">
                    <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                      <div v-if="hazard.type && type.name">
                        <span class="text-capitalize">
                          <label class="overline" style="line-height: 12px">
                            <v-icon color="orange darken-2" small>mdi-alert-box</v-icon
                            ><span class="pl-2" style="position: relative; top: 1px">Risk {{ subTypeIndex + 1 }}</span>
                          </label>
                          <h4>{{ type.name }} : {{ hazard.type }}</h4>
                        </span>
                        <div class="ml-6 mt-2 safety-controls-container">
                          <v-row class="my-2">
                            <v-col cols="auto" class="text-center" style="margin-top: -5px">
                              <v-progress-circular
                                :value="getRiskRating(type.likelihood, type.impact)"
                                :size="90"
                                :width="10"
                                color="yellow darken-2"
                              >
                                <v-row justify="center" class="fill-height">
                                  <v-col class="text-center">
                                    <strong>{{ Math.ceil(getRiskRating(type.likelihood, type.impact)) }}%</strong>
                                    <div class="caption risk-rating">Risk Rating</div>
                                  </v-col>
                                </v-row>
                              </v-progress-circular>
                            </v-col>

                            <v-col class="d-flex flex-column justify-center">
                              <v-progress-linear
                                rounded
                                striped
                                class="mb-4"
                                :color="setTrackColor(type.likelihood) + ' darken-4'"
                                :value="type.likelihood != 0 ? Math.ceil((type.likelihood / 5) * 100) : 5"
                                height="15"
                              >
                                <span class="overline">Likelihood</span>
                              </v-progress-linear>

                              <v-progress-linear
                                rounded
                                striped
                                class="mb-4"
                                :color="setTrackColor(type.impact) + ' darken-4'"
                                :value="Math.ceil((type.impact / 5) * 100)"
                                height="15"
                              >
                                <span class="overline">Impact</span>
                              </v-progress-linear>

                              <v-progress-linear
                                rounded
                                striped
                                class="mb-4"
                                :color="setTrackColor(type.severity) + ' darken-4'"
                                :value="type.severity != 0 ? Math.ceil((type.severity / 5) * 100) : 5"
                                height="15"
                              >
                                <span class="overline">Severity</span>
                              </v-progress-linear>
                            </v-col>
                          </v-row>
                          <!-- Source Evidence section -->
                          <div v-if="hazard.sourceEvidence?.images?.length" class="mt-3 mb-4">
                            <label class="overline mb-2">Source Evidence</label>
                            <div
                              v-for="(evidence, evidenceIndex) in hazard.sourceEvidence.images"
                              :key="'evidence-' + evidenceIndex"
                              class="evidence-item mb-3"
                            >
                              <div class="evidence-container">
                                <!-- Image -->
                                <div class="image-section pa-3">
                                  <div class="image-container clickable" @click="previewImage(evidence.imageSource)">
                                    <img
                                      v-if="evidence.imageSource"
                                      :src="evidence.imageSource"
                                      class="evidence-image"
                                    />
                                    <div v-else class="loading-placeholder">
                                      <v-progress-circular indeterminate></v-progress-circular>
                                    </div>
                                    <div class="image-overlay">
                                      <v-icon color="white">mdi-magnify</v-icon>
                                    </div>
                                  </div>
                                </div>

                                <!-- Details Section -->
                                <div class="evidence-details flex-grow-1 pa-3">
                                  <!-- Observations -->
                                  <div v-if="evidence.observations?.length" class="mb-3">
                                    <div class="d-flex align-center mb-1">
                                      <v-icon x-small color="primary" class="mr-1">mdi-eye</v-icon>
                                      <span class="caption font-weight-medium">Observations</span>
                                    </div>
                                    <ul class="mt-1 mb-0">
                                      <li
                                        v-for="(obs, idx) in evidence.observations"
                                        :key="`obs-${taskIndex}-${hazardIndex}-${evidenceIndex}-${idx}`"
                                        class="caption"
                                      >
                                        {{ obs }}
                                      </li>
                                    </ul>
                                  </div>

                                  <!-- Sentiment -->
                                  <div v-if="evidence.sentiment" class="mb-2">
                                    <div class="d-flex align-center mb-1">
                                      <v-icon x-small color="primary" class="mr-1">mdi-emoticon</v-icon>
                                      <span class="caption font-weight-medium">Sentiment</span>
                                    </div>
                                    <div class="d-flex align-center">
                                      <v-chip
                                        small
                                        :color="getSentimentColor(evidence.sentiment)"
                                        class="px-2 pl-4"
                                        :class="getSentimentColor(evidence.sentiment) === `error` ? 'white--text' : ''"
                                      >
                                        <v-icon x-small left>{{ getSentimentIcon(evidence.sentiment) }}</v-icon>
                                        <span class="ml-1">{{ getSentimentText(evidence.sentiment) }}</span>
                                      </v-chip>
                                    </div>
                                  </div>

                                  <!-- Comments -->
                                  <div v-if="evidence.comments?.length" class="mt-4 comments-section">
                                    <div class="d-flex align-center text-caption">
                                      <v-icon x-small color="primary" class="mr-1">mdi-comment-multiple</v-icon>
                                      {{ evidence.comments.length }} Comments
                                    </div>
                                    <v-list dense class="comment-list pa-0 mt-0">
                                      <v-list-item
                                        v-for="comment in evidence.comments"
                                        :key="comment.createdAt"
                                        class="pa-0"
                                      >
                                        <v-list-item-content>
                                          <v-list-item-subtitle class="text-caption">
                                            <span class="mr-2">
                                              {{ formatDateTimeContextual(comment.createdAt) }}
                                            </span>
                                            <v-chip
                                              v-if="comment.severity"
                                              x-small
                                              label
                                              class="ml-1"
                                              :color="getSeverityColor(comment.severity)"
                                            >
                                              Severity {{ comment.severity }}
                                            </v-chip>
                                          </v-list-item-subtitle>
                                          <v-list-item-title class="text-body-2 mt-1">
                                            {{ comment.text }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </div>
                                </div>
                              </div>

                              <!-- Add relevance if it exists -->
                              <div v-if="hazard.sourceEvidence.relevance" class="evidence-footer px-3 py-2">
                                <div class="caption font-italic">{{ hazard.sourceEvidence.relevance }}</div>
                              </div>
                            </div>
                          </div>
                          <v-divider class="my-4"></v-divider>
                          <!-- Regulatory Codes Section -->
                          <div v-for="code in hazard.regulatoryCodes" :key="'codedItem-' + code.code">
                            <div class="pa-2 pt-0 px-0 mb-2" rounded v-if="code">
                              <div class="d-flex align-center mb-1">
                                <label class="overline mr-2">Regulatory Codes</label>
                                <v-chip x-small :color="getStatusColor(code.status)" label>
                                  {{ code.authority }}
                                  {{ code.code }}
                                </v-chip>
                              </div>
                              <div class="pl-3">
                                <div class="font-weight-medium">{{ code.title }}</div>
                                <div class="text-caption">{{ code.description }}</div>

                                <div v-if="code.complianceAction" class="d-flex align-center mt-1">
                                  <v-icon x-small color="primary" class="mr-1">mdi-arrow-right-circle</v-icon>
                                  <span class="text-caption">{{ code.complianceAction }}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <v-divider class="my-4" v-if="plan?.type === 'report'"></v-divider>
                          <h3>Safety Controls and Verification</h3>

                          <!-- Risk Controls -->
                          <template v-if="splitCommaString(type.controlMeasures).length">
                            <label class="overline">Risk Controls</label>
                            <v-simple-table dense class="mb-4 compact-table">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left" style="width: 100%">Risk Controls</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Verified</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="control in getControlItems(type, 'ctrl')" :key="control.id">
                                    <td>{{ control.label }}</td>
                                    <td class="status-cell">
                                      <v-checkbox
                                        :input-value="!!type.verifications?.[control.id]"
                                        dense
                                        hide-details
                                        :disabled="isCompleted"
                                        @change="(checked) => updateVerification(type, control.id, checked)"
                                      ></v-checkbox>
                                    </td>
                                    <td class="caption date-cell">
                                      {{
                                        type.verifications?.[control.id]
                                          ? formatDateTimeContextual(type.verifications[control.id])
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </template>

                          <!-- Training Requirements -->
                          <template v-if="splitCommaString(type.requiredTraining).length">
                            <label class="overline">Training Required</label>
                            <v-simple-table dense class="mb-4 compact-table">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left" style="width: 100%">Required Training</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Verified</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="control in getControlItems(type, 'train')" :key="control.id">
                                    <td>{{ control.label }}</td>
                                    <td class="status-cell">
                                      <v-checkbox
                                        :input-value="!!type.verifications?.[control.id]"
                                        dense
                                        hide-details
                                        :disabled="isCompleted"
                                        @change="(checked) => updateVerification(type, control.id, checked)"
                                      ></v-checkbox>
                                    </td>
                                    <td class="caption date-cell">
                                      {{
                                        type.verifications?.[control.id]
                                          ? formatDateTimeContextual(type.verifications[control.id])
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </template>

                          <!-- PPE Requirements -->
                          <template v-if="splitCommaString(type.ppe).length">
                            <label class="overline">PPE (Personal Protective Equipment)</label>
                            <v-simple-table dense class="mb-4 compact-table">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left" style="width: 100%">PPE Requirements</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Verified</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="control in getControlItems(type, 'ppe')" :key="control.id">
                                    <td>{{ control.label }}</td>
                                    <td class="status-cell">
                                      <v-checkbox
                                        :input-value="!!type.verifications?.[control.id]"
                                        dense
                                        hide-details
                                        :disabled="isCompleted"
                                        @change="(checked) => updateVerification(type, control.id, checked)"
                                      ></v-checkbox>
                                    </td>
                                    <td class="caption date-cell">
                                      {{
                                        type.verifications?.[control.id]
                                          ? formatDateTimeContextual(type.verifications[control.id])
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </template>

                          <!-- Risk Mitigation Strategies -->
                          <template v-if="splitCommaString(type.mitigationStrategies).length">
                            <label class="overline">{{
                              plan?.type === "report" ? "Actions to reduce risk" : "Strategies to reduce risk"
                            }}</label>
                            <v-simple-table dense class="mb-4 compact-table">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left" style="width: 100%">
                                      {{ plan?.type === "report" ? "Actions" : "Strategies" }}
                                    </th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Verified</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="control in getControlItems(type, 'mit')" :key="control.id">
                                    <td>{{ control.label }}</td>
                                    <td class="status-cell">
                                      <v-checkbox
                                        :input-value="!!type.verifications?.[control.id]"
                                        dense
                                        hide-details
                                        :disabled="isCompleted"
                                        @change="(checked) => updateVerification(type, control.id, checked)"
                                      ></v-checkbox>
                                    </td>
                                    <td class="caption date-cell">
                                      {{
                                        type.verifications?.[control.id]
                                          ? formatDateTimeContextual(type.verifications[control.id])
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </template>

                          <div class="mb-4">
                            <div class="d-flex align-center mb-2">
                              <v-icon small color="primary" class="mr-2">mdi-camera</v-icon>
                              <span class="body-2">Verification Evidence</span>
                            </div>
                            <v-btn
                              small
                              color="primary"
                              @click="addVerificationMedia(task, hazard, type)"
                              :disabled="isCompleted"
                              class="mb-2"
                            >
                              <v-icon left small>mdi-camera</v-icon>
                              Add Evidence Media
                            </v-btn>

                            <div v-if="type.verificationMedia?.length" class="verification-media-grid">
                              <div
                                v-for="media in type.verificationMedia"
                                :key="media.id"
                                class="verification-media-item"
                              >
                                <v-img
                                  :src="media.imageSource"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                                  @click="previewImage(media.imageSource)"
                                >
                                  <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                      <v-progress-circular indeterminate></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>
                            </div>
                          </div>
                          <div class="mb-4 mt-2">
                            <v-textarea
                              v-model="type.comments"
                              label="Verification Comments"
                              rows="2"
                              outlined
                              filled
                              auto-grow
                              dense
                              hide-details
                              :disabled="isCompleted"
                            ></v-textarea>
                            <div
                              class="text-right caption"
                              style="opacity: 0.5"
                              v-if="type.commentsUpdatedOn && type.comments"
                            >
                              {{ formatDateTimeContextual(type.commentsUpdatedOn) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    tasks[taskIndex].documents?.length ||
                    tasks[taskIndex].forms?.length ||
                    tasks[taskIndex].trainings?.length
                  "
                >
                  <div class="overline mt-4">Inline Resources</div>
                  <div class="ml-2">
                    <v-row dense>
                      <v-col cols="12">
                        <div v-if="tasks[taskIndex].documents && tasks[taskIndex].documents.length">
                          <div class="overline">Documents</div>
                          <div
                            class="d-block mr-2 mb-1"
                            v-for="doc in tasks[taskIndex].documents"
                            :key="'doc-' + doc.id"
                          >
                            <v-btn
                              :block="$vuetify.breakpoint.mobile"
                              color="blue darken-2"
                              @click="openDocumentDialog(doc.id)"
                              class="mr-2 white--text custom-btn"
                              style="
                                white-space: normal;
                                height: auto !important;
                                padding-top: 8px !important;
                                padding-bottom: 8px !important;
                              "
                            >
                              <span style="width: 100%; word-break: break-word">{{ doc.name }}</span>
                            </v-btn>
                          </div>
                        </div>
                        <div v-if="tasks[taskIndex].forms && tasks[taskIndex].forms.length">
                          <div class="overline mt-2">Forms</div>
                          <div
                            class="d-block mr-2 mb-1"
                            v-for="form in tasks[taskIndex].forms"
                            :key="'form-' + form.id"
                          >
                            <v-btn
                              :block="$vuetify.breakpoint.mobile"
                              color="green darken-2"
                              @click="
                                openFormDialog(form.id, plannerId, plannerAssignmentId, plannerAssignmentDueDateId)
                              "
                              class="mr-2 white--text custom-btn"
                              style="
                                white-space: normal;
                                height: auto !important;
                                padding-top: 8px !important;
                                padding-bottom: 8px !important;
                              "
                            >
                              <span style="width: 100%; word-break: break-word">{{ form.name }}</span>
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
              <v-textarea
                v-model="overallComments"
                label="Overall Comments"
                rows="2"
                outlined
                filled
                auto-grow
                hide-details
                :disabled="isCompleted"
              ></v-textarea>
              <div class="text-right caption" style="opacity: 0.5" v-if="overallCommentsUpdatedOn && overallComments">
                {{ formatDateTimeContextual(overallCommentsUpdatedOn) }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="pt-4 mx-4">Loading...</v-col>
      </v-row>
    </v-container>

    <!-- Fixed Bottom Action Bar -->
    <v-footer
      v-if="!isCompleted"
      fixed
      padless
      app
      class="action-footer"
      style="height: auto; background-color: transparent !important"
    >
      <v-card
        flat
        width="100%"
        class="px-4 py-2"
        style="border-top: 1px solid rgba(0, 0, 0, 0.12); background-color: transparent !important"
      >
        <div class="d-flex align-center justify-space-between" style="background-color: transparent !important">
          <v-spacer></v-spacer>

          <!-- Action Buttons -->
          <div class="d-flex align-center">
            <v-btn
              @click="submitPlanner(false)"
              :disabled="!isPlanLoaded"
              class="mr-2"
              :style="
                $vuetify.theme.dark ? 'background-color: rgba(0,0,0,0.75)' : 'background-color: rgba(255,255,255,0.75)'
              "
              outlined
              color="primary"
            >
              Save
            </v-btn>

            <v-btn
              @click="submitPlanner(true)"
              color="primary"
              :disabled="!isPlanLoaded || !canSubmit"
              :outlined="!isPlanLoaded || !canSubmit"
            >
              Submit
              <template> ({{ Math.round(formProgress) }}%) </template>
            </v-btn>
          </div>
        </div>

        <!-- Progress Bar -->
        <v-progress-linear :value="formProgress" color="primary" class="mt-2" height="2"></v-progress-linear>
      </v-card>
    </v-footer>

    <!-- Optional: Quick Jump to Top -->
    <v-btn
      v-show="showScrollTop"
      @click="scrollToTop"
      fixed
      bottom
      fab
      x-small
      class="ml-4"
      :style="{
        bottom: $vuetify.breakpoint.mobile ? '76px' : '22px',
      }"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    <form-dialog ref="formDialog" />
    <document-dialog ref="documentDialog" />
    <v-dialog v-model="showImagePreview" max-width="fit-content" content-class="image-preview-dialog">
      <v-card class="image-preview-card">
        <v-card-title class="pa-2" style="position: absolute; z-index: 999; right: 12px; top: 12px">
          <v-btn icon rounded outlined @click="showImagePreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <img :src="selectedImage" class="preview-image" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { PlannerService, AssetService } from "@services";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { DateTime } from "luxon";
import Helpers from "@/mixins/helpers";
import FormDialog from "@/components/forms/FormDialog.vue";
import DocumentDialog from "@/components/documents/DocumentDialog.vue";

export default {
  name: "Planner",

  components: {
    FormDialog,
    DocumentDialog,
  },

  mixins: [Helpers],

  props: {
    plannerId: {
      type: [Number, String],
      default: null,
    },
    plannerAssignmentId: {
      type: [Number, String],
      default: null,
    },
    plannerAssignmentDueDateId: {
      type: [Number, String],
      default: null,
    },
    plannerSubmissionId: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    isPlanLoaded() {
      return Object.keys(this.plan).length > 0;
    },
    newSubmission() {
      return {
        plannerId: this.plannerId,
        plannerAssignmentId: this.plannerAssignmentId,
        plannerDueDateId: this.plannerAssignmentDueDateId,
        name: this.planner.name,
        plannerSchemaJson: JSON.stringify(this.plan),
        plannerSubmissionSchemaJson: JSON.stringify(this.constructSubmissionSchema()),
        submit: false,
      };
    },
    currentStatusCode() {
      return this.submission?.status ? this.submission.status : "1";
    },
    currentStatusText() {
      return this.submission?.status ? this.getStatusText(this.submission.status) : "pending";
    },
    isCompleted() {
      return this.currentStatusText.toLowerCase() === "completed";
    },
    canSubmit() {
      return this.formProgress === 100 && !this.hasValidationErrors;
    },
    formProgress() {
      const allSubTypes = this.tasks.flatMap((task) => task.hazards.flatMap((hazard) => hazard.subTypes));
      let totalControls = 0;
      let verifiedControls = 0;

      allSubTypes.forEach((subType) => {
        const controls = this.getControlItems(subType, "ctrl");
        const training = this.getControlItems(subType, "train");
        const ppe = this.getControlItems(subType, "ppe");
        const mitigation = this.getControlItems(subType, "mit");

        totalControls += controls.length + training.length + ppe.length + mitigation.length;

        if (subType.verifications) {
          verifiedControls += Object.keys(subType.verifications).length;
        }
      });

      return totalControls > 0 ? (verifiedControls / totalControls) * 100 : 0;
    },
  },

  watch: {
    tasks: {
      immediate: true,
      deep: true,
      handler() {
        this.loadImagesForTasks();
      },
    },
  },
  data() {
    return {
      DateTime,
      loading: false,
      plan: {},
      planner: {},
      assignment: {},
      submission: {},
      dueDate: {},
      metaData: {},
      tasks: [],
      requiresSafetyCompliance: false,
      impactThumbValue: ["Negligible", "Minor", "Moderate", "Major", "Severe"],
      severityThumbValue: ["Insignificant", "Minor", "Moderate", "Major", "Catastrophic"],
      likelihoodThumbValue: ["Rare", "Unlikely", "Possible", "Likely", "Almost certain"],
      overallStatus: "",
      startDate: "",
      completionDate: "",
      overallComments: "",
      overallCommentsUpdatedOn: "",
      showScrollTop: false,
      imageCache: new Map(),
      showImagePreview: false,
      selectedImage: null,
      selectedTask: null,
      taskMedia: new Map(),
      verificationStatuses: [
        { text: "Pending Verification", value: "pending" },
        { text: "Verified & Compliant", value: "verified" },
        { text: "Non-Compliant", value: "non-compliant" },
        { text: "Needs Re-verification", value: "needs-review" },
      ],
    };
  },
  methods: {
    openActionDialog(task) {
      this.selectedTask = task;
      this.$refs.plannerActionDialog.openDialog();
    },

    handleActionSubmitted(actionData) {
      console.log("Action submitted:", actionData);
      this.selectedTask = null;
    },
    async loadPlanner(plannerId = null) {
      this.loading = true;
      this.showLoadBar();
      plannerId = plannerId ? plannerId : this.$route.params.plannerId;
      this.loading = true;
      try {
        const response = await PlannerService.getPlanner(plannerId);
        const plan = response.data;
        this.plan = plan;
        this.planner = {
          name: plan.name,
          description: plan.description,
          createdOn: plan.createdOn,
          updatedOn: plan.updatedOn,
        };
        if (plan.metaData) {
          this.metaData = JSON.parse(plan.metaData);
        }
        if (plan.schemaJson) {
          this.tasks = JSON.parse(plan.schemaJson);
          this.tasks = this.tasks.map((task) => ({
            ...task,
            comments: "",
            completed: false,
            hazards: task.hazards.map((hazard) => ({
              ...hazard,
              comments: "",
              subTypes: hazard.subTypes.map((subType) => ({
                ...subType,
                trainingConfirmed: false,
                controlMeasuresReady: false,
                mitigationApplied: false,
                ppeInspected: false,
                comments: "",
              })),
            })),
          }));
        }
      } catch (error) {
        console.error("Error loading planner:", error);
        this.$snackbar.showMessage({
          content: "Failed to load Planner",
          color: "error",
        });
      } finally {
        this.loading = false;
        this.hideLoadBar();
      }
    },

    async loadAssignment(assignmentId = null) {
      assignmentId = assignmentId ? assignmentId : this.$route.params.plannerAssignmentId;

      try {
        this.loading = true;
        const r = await PlannerService.getPlannerAssignment(assignmentId);
        const assignment = r.data;

        let requiresSafetyCompliance = false;
        if (assignment.dueDates && assignment.dueDates.length > 0) {
          const specificDueDate = assignment.dueDates.find((d) => d.id == Number(this.plannerAssignmentDueDateId));
          if (specificDueDate) {
            requiresSafetyCompliance = specificDueDate.requiresSafetyCompliance || false;
            this.dueDate = specificDueDate;
          } else {
            requiresSafetyCompliance = assignment.dueDates.some((dueDate) => dueDate.requiresSafetyCompliance);
          }
        } else {
          requiresSafetyCompliance = assignment.requiresSafetyCompliance || false;
        }
        this.requiresSafetyCompliance = requiresSafetyCompliance;
        this.assignment = assignment;
      } catch (error) {
        console.error("Error loading planner assignment:", error);
        this.$snackbar.showMessage({
          content: "Failed to load Planner assignment",
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    async loadSubmission(submissionId) {
      try {
        this.loading = true;
        const r = await PlannerService.getPlannerSubmission(submissionId);
        this.submission = r.data;
        if (this.submission) {
          const submissionData = JSON.parse(this.submission.plannerSubmissionSchemaJson);

          // Load overall comments
          this.overallComments = submissionData.overallComments || "";
          this.overallCommentsUpdatedOn = submissionData.overallCommentsUpdatedOn;

          // Load task comments
          if (submissionData.tasks) {
            submissionData.tasks.forEach((submittedTask) => {
              const task = this.tasks.find((t) => t.id === submittedTask.id);
              if (task) {
                task.comments = submittedTask.comments || "";
                task.commentsUpdatedOn = submittedTask.commentsUpdatedOn;
              }
            });
          }

          // Load hazard and subtype data
          if (submissionData.hazards) {
            for (const submittedHazard of submissionData.hazards) {
              const matchingTasks = this.tasks.filter((task) =>
                task.hazards.some((h) => h.type === submittedHazard.type),
              );

              for (const task of matchingTasks) {
                const matchingHazard = task.hazards.find((h) => h.type === submittedHazard.type);
                if (matchingHazard) {
                  // Load hazard comments
                  matchingHazard.comments = submittedHazard.comments || "";
                  matchingHazard.commentsUpdatedOn = submittedHazard.commentsUpdatedOn;

                  for (const submittedSubType of submittedHazard.subTypes) {
                    const matchingSubType = matchingHazard.subTypes.find((st) => st.name === submittedSubType.name);
                    if (matchingSubType) {
                      // Load verifications
                      if (submittedSubType.verifications) {
                        this.$set(matchingSubType, "verifications", { ...submittedSubType.verifications });
                      }

                      // Load subtype comments
                      matchingSubType.comments = submittedSubType.comments || "";
                      matchingSubType.commentsUpdatedOn = submittedSubType.commentsUpdatedOn;

                      // Load verification media
                      if (submittedSubType.verificationMedia?.length) {
                        const mediaArray = [];
                        for (const media of submittedSubType.verificationMedia) {
                          try {
                            // Use helper method to get image
                            const imageSource = await this.getImageAsset(media.assetId, media.publicId);

                            if (imageSource) {
                              mediaArray.push({
                                id: Date.now(),
                                assetId: media.assetId,
                                publicId: media.publicId,
                                imageSource, // This will be the blob URL
                                createdAt: media.date,
                                taskId: media.taskId,
                                hazardId: media.hazardId,
                                subTypeId: media.subTypeId,
                                hazardType: media.hazardType,
                                subTypeName: media.subTypeName,
                              });
                            }
                          } catch (error) {
                            console.error("Error loading verification media:", error);
                          }
                        }

                        if (mediaArray.length) {
                          this.$set(matchingSubType, "verificationMedia", mediaArray);
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          if (submissionData.tasks) {
            for (const submittedTask of submissionData.tasks) {
              if (submittedTask.media?.length) {
                const mediaArray = [];

                for (const media of submittedTask.media) {
                  try {
                    const assetResponse = await AssetService.downloadAsset({
                      assetId: media.assetId,
                    });

                    if (assetResponse?.data?.downloadUrl) {
                      const imageBlob = await fetch(assetResponse.data.downloadUrl).then((r) => r.blob());
                      const base64 = await this.blobToBase64(imageBlob);

                      mediaArray.push({
                        id: Date.now(),
                        assetId: media.assetId,
                        imageSource: base64,
                        createdAt: media.createdAt,
                      });
                    }
                  } catch (error) {
                    console.error("Error loading media:", error);
                  }
                }

                if (mediaArray.length) {
                  this.taskMedia.set(submittedTask.id, mediaArray);
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error loading submission:", error);
        this.$snackbar.showMessage({
          content: "Failed to load submission",
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    constructSubmissionSchema() {
      const timestamp = DateTime.now().toISO();
      const currentSubmission = this.submission?.plannerSubmissionSchemaJson
        ? JSON.parse(this.submission.plannerSubmissionSchemaJson)
        : null;

      return {
        overallStatus: this.overallStatus || "",
        startDate: this.startDate || timestamp,
        completionDate: this.completionDate || "",
        tasks: this.tasks.map((task) => {
          const currentTask = currentSubmission?.tasks?.find((t) => t.id === task.id);
          return {
            id: task.id,
            status: task.status || "Incomplete",
            comments: task.comments || "",
            commentsUpdatedOn:
              task.comments !== currentTask?.comments ? timestamp : currentTask?.commentsUpdatedOn || timestamp,
            completedOn: task.completedOn || null,
            media: [],
          };
        }),
        hazards: this.tasks.flatMap((task) =>
          task.hazards.map((hazard) => {
            const currentHazard = currentSubmission?.hazards?.find((h) => h.type === hazard.type);
            return {
              hazardId: hazard.hazardId,
              type: hazard.type,
              comments: hazard.comments || "",
              commentsUpdatedOn:
                hazard.comments !== currentHazard?.comments ? timestamp : currentHazard?.commentsUpdatedOn || timestamp,
              subTypes: hazard.subTypes.map((subType) => {
                const currentSubType = currentHazard?.subTypes?.find((st) => st.name === subType.name);

                // Get all control items for this subtype
                const controls = this.getControlItems(subType, "ctrl");
                const training = this.getControlItems(subType, "train");
                const ppe = this.getControlItems(subType, "ppe");
                const mitigation = this.getControlItems(subType, "mit");
                const allControls = [...controls, ...training, ...ppe, ...mitigation];

                // Build status object for each control
                const statusObj = {};
                allControls.forEach((control) => {
                  statusObj[control.id] = subType.verifications?.[control.id] ? "done" : "pend";
                });

                return {
                  subTypeId: subType.subTypeId,
                  name: subType.name,
                  verifications: subType.verifications || {},
                  status: statusObj,
                  verificationMedia: (subType.verificationMedia || []).map((media) => ({
                    assetId: media.assetId,
                    publicId: media.publicId,
                    date: media.createdAt,
                    taskId: task.id,
                    hazardId: hazard.hazardId,
                    subTypeId: subType.subTypeId,
                    hazardType: hazard.type,
                    subTypeName: subType.name,
                  })),
                  comments: subType.comments || "",
                  commentsUpdatedOn:
                    subType.comments !== currentSubType?.comments
                      ? timestamp
                      : currentSubType?.commentsUpdatedOn || null,
                };
              }),
            };
          }),
        ),
        overallComments: this.overallComments || "",
        overallCommentsUpdatedOn:
          this.overallComments !== currentSubmission?.overallComments
            ? timestamp
            : currentSubmission?.overallCommentsUpdatedOn || timestamp,
        logIts: [],
        formSubmissions: [],
        updatedOn: timestamp,
        completionPercentage: this.formProgress,
      };
    },

    async loadImageSource(evidence) {
      if (!evidence || evidence.imageSource) return;

      try {
        if (this.imageCache.has(evidence.assetId)) {
          this.$set(evidence, "imageSource", this.imageCache.get(evidence.assetId));
          return;
        }

        let imageSource;
        const response = await AssetService.downloadAsset({
          assetId: evidence.assetId,
          token: evidence.publicId,
        });

        if (response?.data?.downloadUrl) {
          const imageBlob = await fetch(response.data.downloadUrl).then((r) => r.blob());
          const base64 = await this.blobToBase64(imageBlob);
          imageSource = `data:image/jpeg;base64,${base64.split(",")[1]}`;
        }

        if (imageSource) {
          this.imageCache.set(evidence.assetId, imageSource);
          this.$set(evidence, "imageSource", imageSource);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    },

    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    // Modify your existing watch or created hook to load images
    async loadImagesForTasks() {
      if (!this.tasks) return;

      for (const task of this.tasks) {
        if (task.hazards) {
          for (const hazard of task.hazards) {
            if (hazard.sourceEvidence?.images) {
              for (const evidence of hazard.sourceEvidence.images) {
                await this.loadImageSource(evidence);
              }
            }
          }
        }
      }
    },
    handleImageError(evidence) {
      console.error("Failed to load image:", evidence);
      this.$set(evidence, "imageLoadError", true);
    },
    calculateCompletionPercentage() {
      const totalTasks = this.tasks.length;
      const completedTasks = this.tasks.filter((t) => t.completed).length;
      return totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
    },

    async submitPlanner(submit) {
      try {
        const submissionData = {
          ...this.newSubmission,
          submit: submit,
          id: this.submission?.id,
        };

        if (this.submission?.id) {
          await PlannerService.patchPlannerSubmission(submissionData);
        } else {
          await PlannerService.createPlannerSubmission(submissionData);
        }

        this.$snackbar.showMessage({
          content: submit ? "Planner submitted successfully" : "Planner saved successfully",
          color: "success",
        });
        this.$router.push("/planners");
      } catch (error) {
        console.error("Error submitting planner:", error);
        this.$snackbar.showMessage({
          content: "Failed to submit planner",
          color: "error",
        });
      }
    },

    async toggleTaskCompletion(taskIndex) {
      const newCompletionStatus = !this.tasks[taskIndex].completed;
      this.$set(this.tasks[taskIndex], "completed", newCompletionStatus);
      if (newCompletionStatus) {
        this.$set(this.tasks[taskIndex], "completedOn", new Date().toISOString());
      } else {
        this.$set(this.tasks[taskIndex], "completedOn", null);
      }
    },

    updateHazardStatus(taskIndex, hazardIndex, subTypeIndex, checked) {
      const subType = this.tasks[taskIndex].hazards[hazardIndex].subTypes[subTypeIndex];
      this.$set(subType, "trainingConfirmed", checked);
    },

    getRiskRating(likelihood, impact) {
      likelihood = Math.max(1, Math.min(5, likelihood));
      impact = Math.max(1, Math.min(5, impact));
      return Math.round(((likelihood * impact - 1) / 24) * 100);
    },

    getStatusColor(status) {
      return (
        {
          compliant: "success",
          "non-compliant": "error",
          "needs-review": "warning",
        }[status] || "grey darken-2"
      );
    },

    splitCommaString(input) {
      return String(input)
        .split(",")
        .map((item) => item.trim())
        .filter(Boolean);
    },

    setTrackColor(val) {
      const colors = ["light-blue", "light-green", "yellow", "orange", "red"];
      return colors[Math.min(Math.floor(val), 4)];
    },

    openFormDialog(formId, plannerId, plannerAssignmentId, plannerAssignmentDueDateId) {
      this.$refs.formDialog.openDialog(formId, plannerId, plannerAssignmentId, plannerAssignmentDueDateId);
    },

    openDocumentDialog(documentId) {
      this.$refs.documentDialog.openDialog(documentId);
    },

    handleScroll() {
      this.showScrollTop = window.scrollY > 500;
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    previewImage(imageSource) {
      this.selectedImage = imageSource;
      this.showImagePreview = true;
    },

    getSentimentColor(sentiment) {
      // Handle string sentiment values
      if (sentiment === "") return "grey";

      // Convert to number if string
      const sentimentNum = Number(sentiment);
      if (isNaN(sentimentNum)) return "grey";

      const colors = {
        2: "green",
        1: "light-green",
        0: "grey",
        "-1": "orange",
        "-2": "red",
      };
      return colors[sentimentNum.toString()] || "grey";
    },

    getSentimentText(sentiment) {
      const sentimentMap = {
        2: "Very Positive",
        1: "Positive",
        0: "Neutral",
        "-1": "Negative",
        "-2": "Very Negative",
      };
      return sentimentMap[sentiment] || "Unknown";
    },

    getSentimentIcon(sentiment) {
      const icons = {
        "-2": "mdi-emoticon-angry-outline",
        "-1": "mdi-emoticon-sad-outline",
        0: "mdi-emoticon-neutral-outline",
        1: "mdi-emoticon-happy-outline",
        2: "mdi-emoticon-excited-outline",
      };
      return icons[sentiment.toString()] || "mdi-emoticon-neutral-outline";
    },

    getSeverityColor(severity) {
      const colors = {
        1: "green",
        2: "yellow darken-2",
        3: "orange",
        4: "red",
      };
      return colors[severity] || "grey";
    },

    async addMedia(task) {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          source: CameraSource.Camera,
        });

        if (image?.base64String) {
          //this.pending();
          const base64Data = new Buffer.from(image.base64String, "base64");
          const photoExt = "jpg";
          const photoName = `planner-photo_${new Date().getTime()}.${photoExt}`;

          const payload = {
            name: photoName,
            description: "",
            originalFileName: photoName,
            mimeType: "image/jpeg",
            cacheable: true,
            permitCompany: true,
          };

          try {
            const response = await AssetService.uploadAssetBegin(payload);

            const params = {
              method: "PUT",
              headers: {
                "Content-Type": "image/jpeg",
                "Content-Encoding": "base64",
              },
              body: base64Data,
            };

            const uploadResponse = await fetch(response.data.uploadUrl, params);

            if (uploadResponse.ok) {
              await AssetService.uploadAssetComplete({ assetId: response.data.assetId });

              // Initialize taskMedia for this task if it doesn't exist
              if (!this.taskMedia.has(task.id)) {
                this.taskMedia.set(task.id, []);
              }

              // Create new media entry
              const newMedia = {
                id: Date.now(),
                assetId: response.data.assetId,
                publicId: response.data.assetGuid,
                imageSource: `data:image/jpeg;base64,${image.base64String}`,
                createdAt: new Date().toISOString(),
              };

              // Add to taskMedia Map
              const mediaArray = this.taskMedia.get(task.id);
              mediaArray.push(newMedia);
              this.taskMedia.set(task.id, mediaArray);

              // Force update
              this.$forceUpdate();

              return response.data.assetId;
            } else {
              await AssetService.deleteAsset({ assetId: response.data.assetId });
              throw new Error("Failed to upload image");
            }
          } catch (error) {
            console.error("Error during asset upload:", error);
            throw error;
          }
        }
      } catch (error) {
        console.error("Error adding media:", error);
      } finally {
        // this.done()
      }
    },

    async addVerificationMedia(task, hazard, type) {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          source: CameraSource.Camera,
        });

        if (image?.base64String) {
          const base64Data = new Buffer.from(image.base64String, "base64");
          const photoExt = "jpg";
          const photoName = `planner-verification-photo_${task.id}_${hazard.type}_${
            type.name
          }_${new Date().getTime()}.${photoExt}`;

          const payload = {
            name: photoName,
            description: `Verification photo for task ${task.id}, hazard ${hazard.type}, type ${type.name}`,
            originalFileName: photoName,
            mimeType: "image/jpeg",
            cacheable: true,
            permitCompany: true,
          };

          try {
            const response = await AssetService.uploadAssetBegin(payload);

            const params = {
              method: "PUT",
              headers: {
                "Content-Type": "image/jpeg",
                "Content-Encoding": "base64",
              },
              body: base64Data,
            };

            const uploadResponse = await fetch(response.data.uploadUrl, params);

            if (uploadResponse.ok) {
              await AssetService.uploadAssetComplete({ assetId: response.data.assetId });

              if (!type.verificationMedia) {
                this.$set(type, "verificationMedia", []);
              }

              const newMedia = {
                id: Date.now(),
                assetId: response.data.assetId,
                publicId: response.data.assetGuid,
                imageSource: `data:image/jpeg;base64,${image.base64String}`,
                createdAt: new Date().toISOString(),
                taskId: task.id,
                hazardId: hazard.hazardId,
                subTypeId: type.subTypeId,
                hazardType: hazard.type,
                subTypeName: type.name,
              };

              type.verificationMedia.push(newMedia);

              this.$forceUpdate();
            } else {
              await AssetService.deleteAsset({ assetId: response.data.assetId });
              throw new Error("Failed to upload verification image");
            }
          } catch (error) {
            console.error("Error during verification asset upload:", error);
            throw error;
          }
        }
      } catch (error) {
        console.error("Error adding verification media:", error);
        this.$snackbar.showMessage({
          content: "Failed to add verification media",
          color: "error",
        });
      }
    },

    updateVerification(type, controlId, checked) {
      if (!type.verifications) {
        this.$set(type, "verifications", {});
      }

      if (checked) {
        this.$set(type.verifications, controlId, DateTime.now().toISO());
      } else {
        this.$delete(type.verifications, controlId);
      }

      this.$forceUpdate();
    },

    parseControlItem(item) {
      if (!item) return { id: "", label: "" };
      const [id, label] = item.split("|");
      return { id: id || this.getControlId(label), label: label || item };
    },

    getControlItems(type, field) {
      const items = this.splitCommaString(
        field === "train"
          ? type.requiredTraining
          : field === "ctrl"
          ? type.controlMeasures
          : field === "ppe"
          ? type.ppe
          : type.mitigationStrategies,
      );
      return items.map((item) => this.parseControlItem(item));
    },

    getControlId(text) {
      const cleaned = text.replace(/[^a-zA-Z0-9]/g, "");
      const prefix = cleaned.slice(0, 2).padEnd(2, "0");
      const suffix = cleaned.slice(-3).padStart(3, "0");
      return (prefix + suffix).toLowerCase();
    },

    getVerificationStatus(field, subType) {
      if (!subType.verifications) return "pend";
      const items = this.getControlItems(subType, field);
      if (!items.length) return "none";

      const verifiedCount = items.filter((item) => subType.verifications[item.id]).length;
      return verifiedCount === 0 ? "pend" : verifiedCount < items.length ? "part" : "done";
    },
  },

  async mounted() {
    await this.loadPlanner();
    await this.loadAssignment();
    if (this.$route.params.plannerSubmissionId) {
      this.loadSubmission(this.$route.params.plannerSubmissionId);
    }
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.planner {
  .caption.risk-rating {
    font-size: 0.6rem !important;
    line-height: 1rem;
  }
  .evidence-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 8px;
    padding-right: 8px;

    .evidence-container {
      @media (min-width: 960px) {
        display: flex;
        gap: 16px;
      }
    }

    .evidence-image {
      max-width: 100%;

      @media (min-width: 960px) {
        width: 350px;
        margin-bottom: 0;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 4px;
      }

      .loading-state {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
      }
    }

    .evidence-content {
      flex: 1;
    }

    .analysis-section {
      background: rgba(0, 0, 0, 0.03);
      //padding: 8px;
      border-radius: 4px;
    }
  }
  .custom-btn {
    display: block !important;
    width: 100% !important;
  }
  .v-btn--active.no-active:not(:hover)::before {
    opacity: 0 !important;
    background-color: transparent !important;
  }
  .v-btn--active::before {
    background-color: transparent !important;
  }
  .v-item-group.v-bottom-navigation .v-btn {
    min-width: 65px;
  }
  .theme--dark.v-bottom-navigation .v-btn.not-active.v-btn--active {
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .theme--light.v-bottom-navigation .v-btn.not-active.v-btn--active {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .nav-bottom {
    border-top: 1px solid #ccc;
    height: auto !important;
    .clock {
      margin: 0 auto;
    }
    .btn-nav {
      padding-top: 7px !important;
      padding-bottom: constant(safe-area-inset-bottom) !important;
      padding-bottom: calc(10px + env(safe-area-inset-bottom)) !important;
    }
    .btn-nav-small {
      font-size: 9px !important;
      font-family: "roboto", sans-serif !important;
      min-width: 50px !important;
    }
    .v-btn:before {
      background-color: transparent;
    }
    @media (max-width: 449px) {
      .btn-nav-small {
        font-size: 8px !important;
      }
    }
  }

  .action-footer {
    background-color: transparent !important;
  }

  .action-footer::before {
    background-color: transparent !important;
  }

  /* Handle iOS safe areas */
  @supports (padding: max(0px)) {
    .footer-card {
      padding-bottom: max(0px, env(safe-area-inset-bottom));
    }
  }

  .image-container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    background: white;
  }

  .clickable {
    cursor: pointer;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .image-container:hover .image-overlay {
    opacity: 1;
  }

  .preview-image {
    background: rgba(0, 0, 0, 0.9);
  }

  .evidence-image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.2s ease;
  }

  .image-container:hover .evidence-image {
    transform: scale(1.05);
  }

  /* Dark theme support */
  :deep(.theme-dark) .image-container {
    border-color: rgba(255, 255, 255, 0.12);
    background: rgba(255, 255, 255, 0.05);
  }

  :deep(.theme-dark) .preview-image {
    background: rgba(0, 0, 0, 0.95);
  }

  .evidence-container {
    display: flex;
    gap: 16px;

    @media (max-width: 959px) {
      flex-direction: column;
    }
  }

  .evidence-details {
    flex: 1;
  }

  .comment-list {
    background: transparent;
  }

  .evidence-footer {
    background: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  :deep(.theme-dark) {
    .evidence-footer {
      background: rgba(255, 255, 255, 0.03);
      border-color: rgba(255, 255, 255, 0.08);
    }
  }

  .loading-placeholder {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.05);
  }

  .image-section {
    width: 350px;
    min-width: 350px;

    @media (max-width: 959px) {
      width: 100%;
      min-width: 100%;
    }
  }

  .verification-media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 8px;
    margin-top: 8px;

    .verification-media-item {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.02);
      }
    }
  }
  .safety-controls-container {
    td {
      height: 48px !important;
    }
    .status-cell {
      .v-input {
        margin-top: -2px;
      }
    }
    .date-cell {
      min-width: 140px;
      text-align: center;
    }
  }
  .compact-table th,
  .compact-table td {
    padding: 4px 8px !important;
  }
  .verification-table {
    ::v-deep {
      tbody tr {
        td {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }

        .v-input--selection-controls {
          margin: 0;
        }
      }

      .v-input--selection-controls__input {
        margin-bottom: 0;
      }

      /* Ensure checkbox container doesn't clip */
      .v-input--selection-controls__ripple {
        transform: translate(-50%, -50%) !important;
      }
    }
  }
}

.image-preview-dialog {
  background: transparent !important;
  box-shadow: none;

  .v-card.image-preview-card {
    background: transparent !important;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-image {
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain;
    margin: auto;
    display: block;
  }

  .v-btn.v-btn--icon {
    background: rgba(0, 0, 0, 0.4);
    color: white;

    &:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }
  &.v-dialog.image-preview-dialog {
    box-shadow: none !important;
    background: transparent !important;
  }
}
</style>
